window.attachFormEvents = function attachFormEvents() {
    var HTMLCollection = document.forms;
    var invalidElements = HTMLCollection[0].getElementsByClassName('is-invalid');

    // Removes is-invalid class on validated elements when each is clicked
    for (const formElement of invalidElements) {
        formElement.onclick = function(event) {
            this.classList.remove('is-invalid');
        }
    }
}